.button {
    font-size: 18px;
    font-family: "BrandonText", sans-serif;
    background: #7c40db;
    border-radius: 8px;
    color: #fff;
    border: 0;
    padding: 18px 79px;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;

    &--secondary {
        background: #bcbcbc;
    }

    &--tertiary {
        color: #242831;
        background: #fff;
        border-radius: 4px;
        border: solid 1px #b8bfd3;
    }
    &--success {
        background-color: #18bc9c;
    }
    .btn-text {
        display: contents;
    }
    &:focus {
        outline: 0;
    }
    &:disabled {
        background-color: #bcbcbc;
        cursor: no-drop;
    }
}
@media (max-width: 400px) {
    .button {
        &--success {
            padding: 10px;
        }
    }
}
