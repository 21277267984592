.modal {
  $self: &;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  background-color: rgba(15, 17, 19, 0.7);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;

  &__content {
    font-size: 14px;
    line-height: 1.71;
    color: #383d49;
    max-width: 300px;
    margin: 0 auto;

    p {
      text-align: justify;
    }

    &--buttons {
      display: flex;
      justify-content: space-between;

      .button {
        padding: 18px 44px;
      }
    }
    &.wide {
      max-width: unset;
    }
  }
  &.big {
    max-width: unset;
  }

  &__wrapper {
    padding: 50px 0;
    margin: auto;
    width: 100%;
  }

  &__body {
    padding: 40px;
    border-radius: 10px;
    background-color: #fff;
    z-index: 3;
    box-sizing: border-box;
    width: 90%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    &.moder-modal {
      width: 900px;
    }
    .text {
      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        color: #ffffff;

        &.big {
          font-size: 16px;
        }
      }
    }

    .info {
      p {
        opacity: 0.5;
        color: #ffffff;
      }
    }
  }
  &.text-center {
    .modal__body {
      width: 400px;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.17px;
    color: #ffc80c;
    margin-bottom: 20px;
  }

  &--user-form,
  &--add-layer,
  &--big {
    #{$self}__body {
      width: 720px;
    }
  }
  &--delete {
    #{$self}__title {
      padding: 0 40px;
    }
    #{$self}__body {
      min-width: 350px;
      padding-left: 0;
      padding-right: 0;
      width: auto;
    }
  }
}

@media (max-width: 1000px) {
  .modal__body.moder-modal {
    width: 800px;
  }
}
@media (max-width: 920px) {
  .modal__body.moder-modal {
    width: 700px;
  }
}
@media (max-width: 800px) {
  .modal__body.moder-modal {
    width: 700px;
  }
}
@media (max-width: 720px) {
  .modal__body.moder-modal {
    width: 600px;
  }
  .modal {
    &.text-center {
      .modal__body {
        width: 70%;
      }
    }
  }
}
@media (max-width: 600px) {
  .modal.text-center .modal__body {
    width: 80%;
  }
}
@media (max-width: 400px) {
  .modal {
    .modal__content {
      img{
        width: 100px;
      }
    }
  }
}
