#podcasts-page {
    .title-area {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .button {
            font-size: 14px;
            border-radius: 25px;
            padding: 10px 23px;
        }
    }
}
@media (max-width: 500px) {
    #podcasts-page .title-area .button {
        font-size: 12px;
        padding: 8px 13px;
    }
}
