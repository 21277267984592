
#no-podcast-page {
    div {
        padding: 50px 0 65px;
    
        h2 {
            font-size: 24px;
            font-weight: 500;
            line-height: 1.75;
            color: #242831;
        }
    }
}
