#header {
  display: flex;
  align-items: center;
  background: transparent;
  height: 134px;
  padding: 0;
  justify-content: space-between;
  z-index: 9999;
  position: absolute;
  margin: 0 10% 20px 10%;
  align-items: center;
  width: 80%;
  transition-duration: 0.5s;
  .btn-container {
    display: inline-flex;
  }
  .sss-btn {
    height: 55px;
    width: 177px;
    text-align: left;
    color: white;
    margin: 0 20px 0 0;
    border: none;
    border-radius: 30px;
    a {
      position: absolute;
      color: white;
      font-size: 18px;
      font-weight: 600;
      margin: 15px 0 0 18px;
    }
  }
  .mypodcast-btn {
    font-size: 20px;
    font-weight: bold;
    line-height: 2.1;
    width: 160px;
    height: 55px;
    text-align: left;
    padding: 15px;
    border: none;
    border-radius: 30px;
    a {
      position: absolute;
      color: #7c40db;
    }
  }
  .fast-login-btn {
    width: 165px;
    height: 55px;
    object-fit: contain;
    cursor: pointer;
  }
  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  #header {
    margin: 0 5% 20px 5%;
    width: 90%;
    transition-duration: 0.5s;
  }
}

@media (max-width: 650px) {
  #header {
    .btn-container {
      display: inline-block;
    }
    .mypodcast-btn {
      position: absolute;
      bottom: 40px;
      right: 10px;
    }
  }
}

@media (max-width: 500px) {
  #header {
    .sss-btn {
      height: 40px;
      width: 100%;
      a {
        font-size: 12px;
        margin: 8px;
      }
    }
    .fast-login-btn {
      width: 100%;
      img {
        width: 120px;
      }
    }
  }
}
@media (max-width: 450px) {
  #header {
    margin-top: 20px;
    display: block;
    .menu {
      padding-left: 10px;

      &__content {
        padding: 5px 0;
      }
    }
    .mypodcast-btn {
      position: relative;
      text-align: center;
      padding: 0;
      line-height: unset;
      height: 40px;
      top: unset;
      bottom: unset;
      right: unset;
      left: unset;
      a {
        position: unset;
      }
    }
  }
}

@media (max-width: 300px) {
  #header {
    display: block;
    .sss-btn {
      height: 40px;
      width: 100%;
      text-align: left;
      a {
        font-size: 10px;
        margin: 8px 0;
      }
    }
    .fast-login-btn {
      width: 100%;
      height: 35px;
      text-align: right;
      img {
        width: 100px;
      }
    }

    .btn-container {
      display: inline-block;
    }
    .mypodcast-btn {
      // font-size: 15px;
      // width: 120px;
    }
  }
}
@media (max-width: 270px) {
  #header {
    .sss-btn {
      height: 40px;
      width: 100%;
      text-align: left;
      a {
        font-size: 10px;
        margin: 8px 0;
      }
    }
    .fast-login-btn {
      width: 100%;
      // height: 35px;
      // text-align: right;
      img {
        width: 90px;
      }
    }
  }
}
