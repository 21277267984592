#faq-page {
    a {
        cursor: pointer;
        font-weight: 600;

        // &:hover {
        //     color: black;
        // }
    }
    .row {
        display: flex;
        .titles {
            .title {
                margin: 5px 0;
                color: #606060;
                padding: 5px 3px;
                a {
                    color: unset;
                    &:hover {
                        color: black;
                    }
                }
            }
            .strong {
                font-weight: 600;
                font-size: 17px;
                color: black;
                // background-color: #e4e4e4;
                // border: 1px solid #606060;
            }
        }
        .mt-5 {
            margin-top: 5px;
        }
        .description {
            margin-left: 2px;
            border: 1px solid #606060;
            background-color: #e4e4e4;
            padding: 10px;
            .title {
                margin: 10px 0 0 0;
                font-weight: 600;
            }
        }
        :first-child {
            // padding: 0 0 0 5px;
        }
        :last-child {
            // padding-left: 0 5px 0 0;
        }
        .col-1 {
            width: 8.33%;
        }
        .col-2 {
            width: 16.67%;
        }
        .col-3 {
            width: 25%;
        }
        .col-4 {
            width: 33.33%;
        }
        .col-5 {
            width: 41.67%;
        }
        .col-6 {
            width: 50%;
            padding: 0 3px;
        }
        .col-7 {
            width: 58.33%;
        }
        .col-8 {
            width: 66.67%;
        }
        .col-9 {
            width: 75%;
        }
        .col-10 {
            width: 83.33%;
        }
        .col-11 {
            width: 91.66%;
        }
        .col-12 {
            width: 100%;
        }
    }

    .accordion {
        flex-direction: column;
    }
    .accordion__item {
        border-radius: 5px;
        border: solid 1px #eaeaea;
        background-color: #fff;
        padding: 5px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .accordion__button {
        padding: 5px 12px;
        font-size: 18px;
        font-family: BrandonText;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: -0.39px;
        color: #242831;
        min-width: 190px;
        text-align: left;
        box-sizing: border-box;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        outline: none;
        i {
            font-size: 8px;
            width: 5%;
        }
        i.icon-triangle {
            transform: rotate(180deg);
            margin-left: -20px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;
        }

        i.icon-triangle.active {
            transform: inherit;
            margin-left: 0;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: -10px;
        }
    }
    .accordion__panel {
        position: relative;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 0 12px;
        box-sizing: border-box;
        border-radius: 0;
        z-index: 0;
        margin-bottom: 0;
        top: 0;
    }

    .faq-title {
        width: calc(100% - 20px);
    }
    .card {
        margin-bottom: 50px;
        transition-duration: 1s;
    }

    @media (max-width: 800px) {
        .card {
            padding: 50px 30px;
        }
        .accordion__button i.icon-triangle {
            margin-right: 0;
        }
    }

    @media (max-width: 600px) {
        .card {
            padding: 50px 20px;
        }
    }

    @media (max-width: 400px) {
        .row {
            .description {
                padding: 5px 3px;
            }
            .mt-5 {
                margin-top: 15px;
            }
        }
        .card {
            padding: 50px 10px;
        }
    }
}
