@import "sweetalert2/src/sweetalert2.scss";

@font-face {
  font-family: "BrandonText";
  src: url("./assets/fonts/BrandonText.eot");
  src: url("./assets/fonts/BrandonText.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/BrandonText.woff2") format("woff2"), url("./assets/fonts/BrandonText.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "BrandonText";
  src: url("./assets/fonts/BrandonText-Medium.eot");
  src: url("./assets/fonts/BrandonText-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/BrandonText-Medium.woff2") format("woff2"),
    url("./assets/fonts/BrandonText-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "BrandonTextBlack";
  src: url("./assets/fonts/BrandonTextBlack.eot");
  src: url("./assets/fonts/BrandonTextBlack.eot") format("embedded-opentype"),
    url("./assets/fonts/BrandonTextBlack.eot") format("woff2"),
    url("./assets/fonts/BrandonTextBlack.woff") format("woff");
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "podcast-cms";
  src: url("./assets/fonts/podcast-cms.eot?54910208");
  src: url("./assets/fonts/podcast-cms.eot?54910208#iefix") format("embedded-opentype"),
    url("./assets/fonts/podcast-cms.woff2?54910208") format("woff2"),
    url("./assets/fonts/podcast-cms.woff?54910208") format("woff"),
    url("./assets/fonts/podcast-cms.ttf?54910208") format("truetype"),
    url("./assets/fonts/podcast-cms.svg?54910208#podcast-cms") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "podcast-cms";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: 0.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before {
  content: "\e800";
}
.icon-triangle:before {
  content: "\e801";
}
.icon-arrow:before {
  content: "\e802";
}
.icon-check:before {
  content: "\e803";
}
html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}
#root {
  height: 100%;
  width: 100%;
  position: relative;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "BrandonText", sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }
}

#content {
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}
.layout {
  padding: 145px 10% 0 10%;
  background: url("./assets/images/backgrounds/background.png");
  background-repeat: repeat-y;
  background-size: cover;
  min-height: 100%;
  width: 100%;
  margin: 0;

  &.login {
    padding: 134px 10% 0 10%;
  }
}

h1 {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.31;
  color: #242831;
  margin: 0 0 15px;
  font-family: "BrandonTextBlack", sans-serif;
}

.notice {
  font-size: 14px;
  line-height: 1.43;
  color: #383d49;
  margin: 0 0 30px;
}

.text-center {
  text-align: center;
}
@media (max-width: 800px) {
  .layout {
    padding: 140px 5% 0 5%;
  }
  h1 {
    font-size: 22px;
  }
}
@media (max-width: 450px) {
  .layout {
    padding: 190px 5% 0 5%;
  }
  h1 {
    font-size: 20px;
  }
}
@media (max-width: 300px) {
  h1 {
    font-size: 18px;
  }
}
