#fast-login-page {
    display: flex;
    justify-content: center;
    .card {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-height: 300px;
        img {
            width: 80px;
        }
        h2 {
            margin:10px 0;
        }
        .error {
            p {
                text-align: center;
            }
        }
        .button {
            padding: 10px 40px;
            font-size: 15px;
        }
    }
}