.podcasts {
    border-radius: 5px;
    border: solid 1px #eaeaea;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;

    .podcast {
        cursor: pointer;
        &--button {
            outline: none;
        }
        &--content {
            display: flex;
            text-align: left;
            .inner-container {
                width: 100%;
                width: -moz-available;
                width: -webkit-fill-available;
                width: fill-available;
                .block-inner {
                    width: 50%;
                }
            }
            &.main-container {
                display: inline-flex;
                width: 100%;
                .img-box {
                    display: inline-block;
                }
                .inner-container {
                    display: block;
                    position: relative;
                    padding-right: 10px;
                    border-radius: 20px;
                    padding: 10px;
                    width: 100%;
                }
                .title-container {
                    display: block;
                }
                .detail-container {
                    display: flex;
                }
            }
            .mw-70 {
                min-width: 70px;
            }
            i {
                font-size: 8px;
                width: unset;

                &.icon-triangle {
                    transform: rotate(180deg);
                    transform-origin: 90% 54%;
                    -ms-transform: rotate(180deg); /* IE 9 */
                    -ms-transform-origin: 90% 54%; /* IE 9 */
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 20px;

                    &:before {
                        margin-right: unset;
                        margin-left: unset;
                    }
                }
            }

            &.active {
                i.icon-triangle {
                    transform: inherit;
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 20px;
                }
            }
        }

        &--info {
            min-width: 25%;
            padding-top: 20px;
            margin-right: 20px;
            img {
                width: 100px;
                height: auto;
            }
            &.img-box {
                width: auto;
                padding: 0;
            }
            &.publisher {
                width: 40%;
            }
            &.title {
                width: 60%;
            }
        }
        &--title {
            font-size: 14px;
            font-weight: 900;
            color: #9b9da4;
            display: block;
        }
        &--text {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            color: #383d49;
            &.starts {
                display: flex;
                text-align: center;
                line-height: 16px;
                margin-bottom: 5px;
                margin-right: 5px;
            }
            span {
                border-radius: 7.5px;
                width: 50px;
                font-size: 8px;
                margin-right: 5px;

                &.fizy {
                    background-color: rgba(255, 230, 74, 0.8);
                    color: #050505;
                }
                &.dergilik {
                    background-color: #4b4384;
                    color: #fff;
                }
            }
            &.status {
                &.fail {
                    color: red;
                }
                &.in-progress {
                    color: #ffe100;
                }
                &.draft {
                    color: orange;
                }
                &.success {
                    color: green;
                }
            }
        }

        &.overlay {
            opacity: 0.5;
        }
    }
}

@media (max-width: 1130px) {
    .podcasts {
        padding: 10px;
        .podcast {
            &--title {
                font-size: 12px;
            }
            &--text {
                font-size: 13px;
                font-weight: 300;
            }
            &--info {
                min-width: 40%;
                padding-top: 10px;
            }
            &--content {
                .inner-container {
                    display: block;

                    .block-inner {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .podcasts {
        padding: 10px;

        .podcast {
            &--title {
                font-size: 12px;
            }
            &--content {
                .inner-container {
                    display: block;
                    .block-inner {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}@media (max-width: 680px) {
    .podcasts{
        .podcast--content{
            flex-wrap: wrap;
        }

    }

    .podcast{
        &.mod-buttons{
            width: 25% !important;
            margin-top: 20px; 
        }
    } 
}
     


@media (max-width: 550px) {
    .podcasts {
        .podcast {
            &--info {
                width: 50%;
                img {
                    width: 70px;
                }
                &.img-box {
                    min-width: 70px;
                    width: 70px;
                }
            }
            &--content {
                .inner-container {
                    display: block;
                }
            }
        }
    }
}
@media (max-width: 350px) {
    .podcasts {
        .podcast--content {
            display: block;
            i.icon-triangle {
                display: block;
                width: 7px;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 10px;
                position: absolute;
                margin-top: 50%;
            }
            &.active {
                i.icon-triangle {
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 10px;
                    margin-bottom: 10px;
                    position: absolute;
                    margin-top: 50%;
                }
            }
        }
        .podcast {
            &--info {
                width: 100%;
                text-align: center;
                &.img-box {
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}