#home-page {
    display: flex;
    justify-content: center;
    background-image: url("../../assets/images/backgrounds/landing-background.png"),
        url("../../assets/images/backgrounds/landing-second-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background: radial-gradient(circle, rgba(255, 255, 255, 0) 19%, rgba(0, 0, 0, 0.8603816526610644) 84%);
    video {
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .slogan {
        width: 100%;
        text-shadow: 0 5px 10px rgba(22, 19, 43, 0.5);
        font-family: "BrandonText";
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        text-align: center;
        color: #ffffff;
        margin-top: 230px;
        p {
            padding: 0;
            margin: 0;
            &:first-child {
                font-size: 48px;
                font-weight: normal;
                letter-spacing: 2.6px;
            }
            &:last-child {
                font-size: 62px;
                font-weight: 900;
                letter-spacing: normal;
            }
        }
    }
}
@media (max-width: 600px) {
    #home-page {
        .slogan {
            margin-top: 150px;
        }
    }
}
@media (max-width: 500px) {
    #home-page {
        .slogan {
            margin-top: 100px;
        }
    }
}
@media (max-width: 400px) {
    #home-page {
        .slogan {
            margin-top: 90px;
        }
    }
}
@media (max-width: 300px) {
    #home-page {
        .slogan {
            margin-top: 70px;
            p {
                &:first-child {
                    font-size: 28px;
                }
                &:last-child {
                    font-size: 42px;
                }
            }
        }
    }
}
