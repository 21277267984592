.episodes {
    display: block;
    margin-top: 20px;
    .episode {
        border-radius: 5px;
        border: 1px solid #dcdcdc;
        padding: 10px 10px;
        margin: 10px 30px;
    }
    .notification {
        text-align: center;
    }
    .episode:nth-child(odd) {
        background: #f7f6f6;
    }
    .episode--content {
        display: flex;
        &.main-container {
            display: inline-flex;
            width: 100%;
            .img-box {
                display: inline-block;
            }
            .inner-container {
                display: block;
                position: relative;
                width: 100%;
                padding-right: 10px;
                border-radius: 20px;
                padding: 10px;
            }
            .title-container {
                display: block;
            }
            .detail-container {
                display: flex;
            }
        }
        .info-container {
            margin-top: auto;
            margin-bottom: auto;
            width: 100%;
            text-align: left;
        }
        .episode--info {
            display: inline-block;
            text-align: left;
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 10px;
            vertical-align: text-top;
            &.title-border {
                border: 1px solid lightgray;
                border-radius: 8px;
                padding: 0px 10px;
            }
            &.img-box {
                width: 120px;
                padding: 10px;
                text-align: center;
                img {
                    width: 80px;
                    height: auto;
                }
            }
            &.title {
                width: 50%;
                &.player {
                    text-align: left;
                    width: 100%;
                    &.merge {
                        width: 100%;
                    }
                    &.divide {
                        width: 70%;
                    }
                    &.divide-60 {
                        width: 60%;
                    }
                    audio {
                        width: 100%;
                    }
                }
                &-container {
                    width: 100%;
                    min-height: 30px;
                    margin-bottom: 10px;
                    border: 1px solid #edecec;
                    border-radius: 10px;
                }
            }
            &.btn-container {
                text-align: center;
                margin-left: 10px;
            }
        }
        .episode--title {
            font-size: 10px;
            font-weight: 900;
            color: #9b9da4;
            display: block;
            &.fixed {
                width: calc(100% - 95px);
                padding: 3px;
                font-size: 15px;
                color: black;
                font-weight: 500;
                text-align: left;
                padding-left: 10px;
            }
        }
        .episode--text {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.14;
            color: #383d49;
            &.date {
                position: absolute;
                text-align: right;
                right: 20px;
                top: 20px;
                color: #a7a7a7;
                font-weight: 300;
                color: #909090;
                font-weight: 300;
                font-size: 11px;
            }
        }
    }
}
@media (max-width: 992px) {
    .episodes {
        .episode {
            &--info {
                padding-top: 10px;
                &.title-border {
                    margin-top: 9px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .episodes {
        .episode {
            &--info {
                flex: 0 0 auto; 
            }
        }
        .episode--content{
            &.main-container{
                .detail-container{
                    flex-wrap: wrap;
                     justify-content: center;
                }
            } 
            .episode--info.title.player.divide-60{
                width: 100%;
            }
        }
        
    }
     
}
@media (max-width: 520px) {
    .episodes {
        .episode {
            &--content {
                .episode--info {
                    width: 33%;
                    &.epi-title {
                        width: 100%;
                    }
                    .episode {
                        &--text {
                            &.epi-date {
                                font-size: 10px;
                                line-height: 1.6;
                            }
                        }
                    }
                    &.img-box {
                        width: 90px;
                        img {
                            width: 70px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 460px) {
    .episodes {
        .episode {
            margin: 10px 10px;
            &--content {
                display: inline-block;
                .episode--info {
                    text-align: center;
                    width: 33%;
                    .episode {
                        &--text {
                            &.epi-date {
                                font-size: 9px;
                                display: block;
                                margin-top: 3px;
                            }
                        }
                        &--info {
                            &.epi-title {
                                width: 100%;
                            }
                        }
                    }
                    &.img-box {
                        width: 60px;
                        padding: 5px;
                        img {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 360px) {
    .episodes {
        .episode--content{
            &.main-container{
                .detail-container{
                    flex-direction: column;
                }
            } 
            .episode--title.fixed{
                font-size: 12px;
            }
        }
    }
    
}
@media (max-width: 300px) {
    .episodes {
        .episode {
            &--content {
                .episode--info {
                    width: 100%;
                }
            }
        }
    }
}
