.card {

    border-radius: 6px;
    border-top-right-radius: 1px;
    box-shadow: 5px 5px 10px 0 rgba(139, 139, 139, 0.2);
    background-color: #ffffff;
    padding: 50px 80px 35px;
    width:  100%;
    margin: 0;
    box-sizing: border-box;
} 