.sort-filter {
  .sleect {
    &__head {
      border-radius: 20px;
    }
  }
  display: inline-block;
  margin-bottom: 10px !important;
  .select--small {
    min-width: 140px;
  }
  .select__selections,
  .select__option {
    font-size: 12px;
  }
  .select__option {
    padding: 5px 10px;
  }
}
.sort-filter.select.select--small.select--selected {
  .select__options {
    width: 153px;
  }
  .select__head {
    min-height: 40px;
    width: 155px;
    padding: 0;
    border-radius: 20px;

    .select__selections,
    .select__option {
      padding: 5px 10px;
    }
    .icon {
      top: 0px;
      right: 12px;
      &:before {
        font-size: 5px;
      }
    }
  }
}
.sort-filter.select.select--small.select--opened.select--selected {
  .select__options {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  .select__head {
    border-radius: unset;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .icon {
      top: 28px;
      right: 13px;
    }
  }
}
.verify-cat.select.select--small.select--selected {
  margin-bottom: 10px;
  .select__head {
    min-height: 56px;
  }
}
.verify-cat.select.select--small.select--opened.select--selected {
  .select__head .icon {
    top: 33px;
    right: 16px;
  }
}
.verify-cat.verify-error.select.select--small.select--selected {
  border: 1px solid #f3495a;
  border-radius: 8px;
  display: flex;
}
.select {
  $self: &;
  position: relative;
  max-width: 300px;
  margin-bottom: 20px;

  &__head {
    position: relative;
    border-radius: 8px;
    border: solid 1px rgba(188, 188, 188, 0.7);
    cursor: pointer;
    min-height: 56px;
    padding: 10px 16px 10px 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .select--opened & {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .icon {
      position: absolute;
      right: 20px;
      top: 14px;
      color: #1e2125;
      height: 8px;
      &:before {
        font-size: 8px;
      }
      .select--opened & {
        transform: rotate(-180deg);
        top: 35px;
      }
    }
  }

  &__selections {
    display: none;
    font-size: 16px;
    font-weight: 500;
    color: #6c707c;
    padding-right: 20px;

    .select--selected & {
      display: block;
    }
  }
  &__options {
    max-height: 206px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 9;
    background: #fff;
    overflow-x: hidden;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: solid 1px rgba(188, 188, 188, 0.7);
    border-top: 0;
    display: none;
    .select--opened & {
      display: block;
    }
  }

  &__option {
    font-size: 16px;
    font-weight: 500;
    color: #6c707c;
    padding: 16px;
    border-bottom: 1px solid #fcfcfc;
    cursor: pointer;
    display: flex;
  }

  &__error-message {
    display: none;
    font-size: 12px;
    font-weight: 500;
    color: #f3495a;
    margin: 5px 0;

    .select-error & {
      display: block;
    }
  }

  &--error {
    #{$self}__head {
      border-color: #f3495a;
    }
  }

  &--small {
    margin-bottom: 0;
    min-width: 85px;

    .select__head {
      padding: 5px 10px;
      min-height: 35px;

      .icon {
        top: 13px;
        top: 7px;
        right: 10px;
      }
    }

    .select__options {
      width: 98%;
    }
  }
  &--disabled {
    .select__head {
      background: #eaeaea;
    }

    .icon-arrow {
      color: #80848d;
    }
  }

  &--opened {
    .select__head .icon {
      top: 20px;
      right: 6px;
    }
  }
}
.card {
  transition-duration: 0.5s;
}
@media (max-width: 850px) {
  .card {
    padding: 50px 20px;
    transition-duration: 0.5s;
  }
  .select {
    &__selections {
      font-size: 12px;
    }
    &__option {
      font-size: 13px;
    }
  }
}

@media (max-width: 410px) {
  .select {
    &--small {
      min-width: 75px;
      .select__head {
        .icon {
          top: 7px;
          right: 10px;
          transition-duration: 0.1s;
        }
      }
    }
    &--opened .select__head .icon {
      top: 20px;
      right: 6px;
      transition-duration: 0.1s;
    }
  }
}
