.podcasts {
    border-radius: 5px;
    border: solid 1px #181414;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;

    .podcast {
        text-align: center;
        cursor: inherit !important;

        &--button {
            outline: none;
        }
        &--content {
            display: flex;

            i {
                font-size: 8px;
                width: 5%;
                cursor: pointer;

                &.icon-triangle {
                    transform: rotate(180deg);
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                }
            }

            &.active {
                i.icon-triangle {
                    transform: inherit;
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                }
            }
        }

        &--info {
            padding-top: 20px;
            margin-right: 20px;

            &.w20 {
                width: 20%;
            }
            img {
                width: 100px;
                height: auto;
            }

            &.img-box {
                width: auto;
                padding: 0;
            }

            &.title {
                width: 21%;
            }
        }

        &--title {
            font-size: 14px;
            font-weight: 900;
            color: #9b9da4;
            display: block;
        }

        &--text {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            color: #383d49;

            &.starts {
                display: flex;
                text-align: center;
                line-height: 16px;
                margin-bottom: 5px;
                margin-right: 5px;
            }

            span {
                border-radius: 7.5px;
                width: 50px;
                font-size: 8px;
                margin-right: 5px;

                &.fizy {
                    background-color: rgba(255, 230, 74, 0.8);
                    color: #050505;
                }

                &.dergilik {
                    background-color: #4b4384;
                    color: #fff;
                }
            }
            &.status {
                &.fail {
                    color: red;
                }
                &.in-progress {
                    color: #ffe100;
                }
                &.draft {
                    color: orange;
                }
                &.success {
                    color: green;
                }
            }
        }

        &.overlay {
            opacity: 0.5;
        }
        .moderation {
            &-button {
                width: 85px;
                height: 35px;
                margin: 0 0 10px;
                padding: 0px 0px 5px 0;
                border-radius: 4px;
                font-family: BrandonText;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;

                .text {
                    width: 45px;
                    height: 30px;
                    margin: 0 0 0 4px;
                    display: contents;
                }

                &:before {
                    width: 10px;
                    height: 10px;
                    margin: 4px 2px 0px 0px;
                    object-fit: contain;
                    position: relative;
                    top: 1px;
                }
                &.confirm {
                    color: #40aa86;
                    border: solid 1px #40aa86;
                    background-color: rgba(220, 255, 243, 0.05);
                    &:hover {
                        background-color: #ddfff4;
                    }
                    &:before {
                        content: url("../../../assets/images/icons/icon-check.svg");
                        margin-right: 2px;
                    }
                    &.confirmed {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }

                &.reject {
                    color: #dd7376;
                    border: solid 1px #dd7376;
                    background-color: #e62c310d;
                    &:hover {
                        background-color: #ffe9e9;
                    }
                    &:before {
                        content: url("../../../assets/images/icons/icon-reject.svg");
                        margin-right: 2px;
                    }
                    &.rejected {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }
            }
        }
    }
}

.moderation-btn-container {
    text-align: right;
}

textarea.moderation {
    border: solid 1px rgba(188, 188, 188, 0.7);
}

.button.moderation {
    &-btn {
        width: 120px;
        height: 50px;
        margin: 0 0 10px;
        padding: 0 0 3px 0;
        border-radius: 4px;

        font-family: BrandonText;
        font-size: 14px;
        font-weight: bold;

        &.cancel {
            color: #000000;
            border: solid 1px #eeeeee;
            background-color: #ffffff;
        }
        &.save {
            color: #ffffff;
            border: solid 1px #eeeeee;
            background-color: #7c40db;
            &:disabled {
                background-color: #bcbcbc;
            }
        }
    }
}

.filter {
    &-button {
        width: 139px;
        height: 40px;
        margin: 0 10px 20px 0;
        padding: 12px 0 11px;
        border-radius: 4px;
        background-color: #a49cb1;

        font-family: BrandonText;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        &:hover {
            background-color: #917db3;
        }
        &.active {
            background-color: #7c40db;
        }
        &:disabled {
            cursor: wait;
        }
    }
}

@media (max-width: 800px) {
    .podcasts {
        padding: 10px;

        .podcast {
            &--title {
                font-size: 12px;
            }
            &--text {
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
}
@media (max-width: 550px) {
    .podcasts {
        .podcast {
            &--info {
                img {
                    width: 70px;
                }
            }
        }
    }
}
