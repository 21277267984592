.loader {
  margin: 20px  auto;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid gray;
  border-right: 5px solid gray;
  border-bottom: 5px solid gray;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  &.overlay {
    position: absolute;
    z-index: 9999;
    left: 50%;
  }
  &.not-overlay {
    position: relative;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}