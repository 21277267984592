.custom-tooltip {
    display: contents;
    align-items: center;
    cursor: pointer;
    #tooltip {
        color: #000 !important;
        font-weight: 100;
        background-color: #fff !important;
        font-size: 11px;
        width: 120px;
        box-shadow: 0px 0px 5px -2px rgba(237, 237, 237, 1);
        &.place-top {
            &:after {
                border: none;
            }
        }
    }
}
