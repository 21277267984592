textarea {
  resize: none;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #242831;
  font-family: "BrandonText", sans-serif;

  &:focus {
    outline: 0;
  }
  &:disabled {
    background: #f5f5f5;
  }
}

.input {
  margin-bottom: 20px;
  &.input--disabled {
    background: #bcbcbc14;
    .input__area {
      background: #bcbcbc14;
    }
  }
  label {
    border-radius: 8px;
    border: solid 1px rgba(188, 188, 188, 0.7);
    display: flex;
  }

  &__area {
    flex-grow: 1;
    padding-top: 20px;
    padding-bottom: 8px;
    padding-left: 16px;
    position: relative;
    text-align: left;
  }

  &__label {
    font-size: 14px;
    color: #6c707c;
    font-weight: bold;
    position: absolute;
    top: 15px;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;

    .input--filled & {
      transform-origin: left top;
      transform: translate3d(0, -65%, 0);
      font-size: 12px;
    }
  }

  input {
    width: calc(100% - 20px);
    display: block;
    box-sizing: border-box;
    border: none;
    background: none;
    padding: 3px 16px 3px 0;
    font-size: 14px;
    font-weight: 500;
    color: #242831;
    font-family: "BrandonText", sans-serif;

    &:focus {
      outline: none;
    }
  }

  &__error-message {
    display: none;
    font-size: 12px;
    font-weight: 500;
    color: #f3495a;
    margin: 5px 0;
    text-align: left;

    .input--error & {
      display: block;
    }
  }

  &--error {
    label {
      border-color: #f3495a;
    }

    input,
    i {
      color: #f3495a;
    }
  }
}

p.input-length {
  text-align: right;
  font-size: 12px;
  &.full {
    color: red;
  }
}
