.infinite-scroll-component__outerdiv {
    width: 100%;
    border-top: 1px solid #eaeaea;
    .error {
        font-size: 13px;
    }
}

.infinite-scroll-component {
    text-align: center;
}
.podcasts {
    .infinite-scroll-component {
        border: 1px solid #efefef;
    }
}
