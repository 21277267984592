.checkbox {
    display: block;
    margin-bottom: 30px;
  
    input {
      display: none;
    }
  
    &__label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      padding-left: 30px;
      height: 25px;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.42;
      color: #242831;
  
      &:before {
        content: '';
        position: absolute;
        width: 25px;
        height: 25px;
        left: 0;
        top: -3px;
        box-sizing: border-box;
        border-radius: 5.8px;
        border: solid 2.2px #d8d8d8;
        background-color: #ffffff;
      }
  
      &:after {
        content: "\e803";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 4px;
        top: 2px;
        box-sizing: border-box;
        display: none;
        font-family: podcast-cms !important;
        font-style: normal;
        font-weight: normal !important;
        vertical-align: top;
        font-size: 12px;
        color: #fff;
      }
  
      input:checked + & {
        &:before {
          background: #41bbd7;
          border-color: #41bbd7;
        }
        &:after {
          display: flex;
        }
      }
  
   
    }  &--bordered {
         height: 50px;
         border-radius: 8px;
         border: solid 1px #3d444b;
         display: flex;
         align-items: center;
         padding-left: 14px;
  
         .checkbox {
           &__label {
             padding-left: 35px;
           }
         }
       }
       &--disabled {
           opacity: .5 ;
       }
  }