#podcasts-page {
    .title-area {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        .button {
            font-size: 14px;
            border-radius: 25px;
            padding: 10px 23px;
        }
    }
}
.filter-container {
    width: 100%;
}

.podcasts {
    border-radius: 5px;
    border: solid 1px #181414;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;

    .podcast {
        text-align: center;
        cursor: inherit !important;

        &--button {
            outline: none;
        }
        &--content {
            display: flex;

            i {
                font-size: 8px;
                width: 5%;
                cursor: pointer;

                &.icon-triangle {
                    transform: rotate(180deg);
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                }
            }

            &.active {
                i.icon-triangle {
                    transform: inherit;
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                }
            }
        }

        &--info {
            padding-top: 20px;
            margin-right: 20px;
            overflow-wrap: break-word;
            &.padding-zero {
                padding-top: 0;
            }
            &.w20 {
                width: 20%;
                min-width: auto;
            }
            &.w33 {
                width: 33%;
            }
            &.w50 {
                width: 50%;
            }
            &.w80 {
                width: 80%;
            }
            img {
                width: 100px;
                height: auto;
            }

            &.img-box {
                width: auto;
                padding: 0;
                min-width: 100px;
            }

            &.title {
                width: 21%;
            }
        }

        &--title {
            font-size: 14px;
            font-weight: 900;
            color: #9b9da4;
            display: block;
        }

        &--text {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            color: #383d49;

            &.starts {
                display: flex;
                text-align: center;
                line-height: 16px;
                margin-bottom: 5px;
                margin-right: 5px;
            }

            span {
                border-radius: 7.5px;
                width: 50px;
                font-size: 8px;
                margin-right: 5px;

                &.fizy {
                    background-color: rgba(255, 230, 74, 0.8);
                    color: #050505;
                }

                &.dergilik {
                    background-color: #4b4384;
                    color: #fff;
                }
            }
            &.status {
                &.fail {
                    color: red;
                }
                &.in-progress {
                    color: #ffe100;
                }
                &.draft {
                    color: orange;
                }
                &.success {
                    color: green;
                }
            }
        }

        &.overlay {
            opacity: 0.5;
        }
        &.mod-buttons {
            align-self: center;
        }
        .moderation {
            &-button {
                width: 90px;
                height: 35px;
                margin: 0 0 10px;
                padding: 0 0 5px 0;
                border-radius: 20px;

                font-family: BrandonText;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;

                .text {
                    width: 45px;
                    height: 30px;
                    margin: 0 0 0 4px;
                    display: contents;
                }
                &.confirm {
                    color: #40aa86;
                    border: solid 1px #40aa86;
                    background-color: rgba(220, 255, 243, 0.05);
                    &:hover {
                        background-color: #ddfff4;
                    }
                    &:before {
                        content: url("../../assets/images/icons/icon-check.svg");
                    }
                    &.confirmed {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }

                &.reject {
                    color: #dd7376;
                    border: solid 1px #dd7376;
                    background-color: #e62c310d;
                    &:hover {
                        background-color: #ffe9e9;
                    }
                    &:before {
                        content: url("../../assets/images/icons/icon-reject.svg");
                    }
                    &.rejected {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }
            }
        }
    }
}

.ai-episodes,
.ai-episode-container {
    border-radius: 5px;
    border: none;
    background-color: #fff;
    padding: 0;
    margin-bottom: 10px;
    .episodes {
        .episode {
            padding: 0;
            margin: 0;
            .moderation {
                &-button {
                    width: 75px;
                    height: 30px;
                    padding: 0 0 3px;
                    margin: 5px;
                    font-size: 11px;
                    border-radius: 15px;
                    line-height: 30px;
                }
                &:before {
                    margin: 4px 2px 0px 0px;
                    top: 1px;
                }
            }
        }
    }
    .podcast {
        text-align: center;
        cursor: inherit !important;

        &--button {
            outline: none;
        }
        &--content {
            display: flex;

            i {
                font-size: 8px;
                width: 5%;
                cursor: pointer;

                &.icon-triangle {
                    transform: rotate(180deg);
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                }
            }

            &.active {
                i.icon-triangle {
                    transform: inherit;
                    margin-left: 0;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 10px;
                }
            }
        }

        &--info {
            padding-top: 20px;
            margin-right: 20px;
            overflow-wrap: break-word;
            &.padding-zero {
                padding-top: 0;
            }
            &.w20 {
                width: 20%;
                min-width: auto;
            }
            &.w33 {
                width: 33%;
            }
            &.w50 {
                width: 50%;
            }
            &.w80 {
                width: 80%;
            }
            img {
                width: 100px;
                height: auto;
            }

            &.img-box {
                width: auto;
                padding: 0;
                min-width: 100px;
            }

            &.title {
                width: 21%;
            }
        }

        &--title {
            font-size: 14px;
            font-weight: 900;
            color: #9b9da4;
            display: block;
        }

        &--text {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            color: #383d49;

            &.starts {
                display: flex;
                text-align: center;
                line-height: 16px;
                margin-bottom: 5px;
                margin-right: 5px;
            }

            span {
                border-radius: 7.5px;
                width: 50px;
                font-size: 8px;
                margin-right: 5px;

                &.fizy {
                    background-color: rgba(255, 230, 74, 0.8);
                    color: #050505;
                }

                &.dergilik {
                    background-color: #4b4384;
                    color: #fff;
                }
            }
            &.status {
                &.fail {
                    color: red;
                }
                &.in-progress {
                    color: #ffe100;
                }
                &.draft {
                    color: orange;
                }
                &.success {
                    color: green;
                }
            }
        }

        &.overlay {
            opacity: 0.5;
        }
        &.mod-buttons {
            align-self: center;
        }
        .moderation {
            &-button {
                width: 90px;
                height: 35px;
                margin: 0 0 10px;
                padding: 0 0 5px 0;
                border-radius: 20px;

                font-family: BrandonText;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 33px;
                letter-spacing: normal;
                text-align: center;

                .text {
                    width: 45px;
                    height: 30px;
                    margin: 0 0 0 4px;
                    display: contents;
                }
                &.confirm {
                    color: #40aa86;
                    border: solid 1px #40aa86;
                    background-color: rgba(220, 255, 243, 0.05);
                    &:hover {
                        background-color: #ddfff4;
                    }
                    &:before {
                        margin-right: 2px;
                        content: url("../../assets/images/icons/icon-check.svg");
                    }
                    &.confirmed {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }

                &.reject {
                    color: #dd7376;
                    border: solid 1px #dd7376;
                    background-color: #e62c310d;
                    &:hover {
                        background-color: #ffe9e9;
                    }
                    &:before {
                        margin-right: 2px;
                        content: url("../../assets/images/icons/icon-reject.svg");
                    }
                    &.rejected {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }

                &.info {
                    color: #000000;
                    border: solid 1px #808080;
                    background-color: #ffffe0;
                    &:hover {
                        background-color: #f7fb92;
                    }
                    &:before {
                        margin-right: 2px;
                    }
                    &.confirmed {
                        cursor: default;
                        border: unset;
                        background-color: unset;
                    }
                }
            }
        }
    }
}

.ai-episodes {
    .modal {
        .mo-podcast {
            border: 1px solid #eeeeee;
            background-color: #f3f3f3;
            padding: 10px;

            .moderation {
                &-button {
                    &.reject {
                        &.rejected {
                            margin-top: auto;
                            margin-bottom: auto;
                            padding: 0 6px;
                            &:before {
                                height: 1px;
                            }
                        }
                    }
                    &.confirm {
                        &.confirmed {
                            margin-top: auto;
                            margin-bottom: auto;
                            padding: 0 6px;
                            &:before {
                                height: 1px;
                            }
                        }
                    }
                }
            }

            .podcast {
                &.mod-buttons {
                    display: grid;
                    align-self: center;
                }
            }
        }
    }
}

audio {
    height: 40px;
}
.moderation-btn-container {
    text-align: right;
    .message {
        &.error {
            color: red;
        }
        &.success {
            color: green;
        }
    }
}
textarea.moderation {
    border: solid 1px rgba(188, 188, 188, 0.7);
    margin: 20px 0;
}
.moderation .input {
    max-width: unset;
}
.input.moderation.input {
    max-width: unset !important;
    margin: 20px 0;
    width: auto;
}
.button.moderation {
    &-btn {
        width: 120px;
        height: 50px;
        margin-top: 10px;
        margin-left: 20px;
        padding: 0 0 3px 0;
        border-radius: 4px;
        font-family: BrandonText;
        font-size: 11px;
        font-weight: bold;

        &.cancel {
            color: #000000;
            border-radius: 4px;
            border: solid 1px #b8bfd3;
            background-color: #ffffff;
        }
        &.save {
            color: #ffffff;
            border: solid 1px #6131af;
            background-color: #7c40db;
            border-radius: 8px;
            &:disabled {
                border: solid 1px #928e9a;
                background-color: #afacb3;
            }
        }

        &:before {
            width: 15px;
            height: 15px;
            margin: 3px 4px 2px 0;
            object-fit: contain;
            position: relative;
            top: 2px;
        }

        &.load {
            width: 120px;
            height: 40px;
            color: #40aa86;
            border: solid 1px #63dbb3;
            background-color: #f1fffb;
            &:hover {
                background-color: #d1fff0;
            }
        }
    }
}
.podcasts {
    .episode {
        .moderation {
            &-button {
                width: 75px;
                height: 25px;
                padding: 0 0 3px;
                margin: 0 0 3px;
                font-size: 11px;
                border-radius: 15px;
            }
            &:before {
                margin: 4px 2px 0px 0px;
                top: 1px;
            }
        }
    }
}

.changelogs {
    .changeline {
        border-bottom: 1px dashed #cfcfcf;
    }
}

.podcasts {
    .modal {
        .mo-podcast {
            border: 1px solid #eeeeee;
            background-color: #f3f3f3;
            padding: 10px;

            .moderation {
                &-button {
                    &.reject {
                        &.rejected {
                            margin-top: auto;
                            margin-bottom: auto;
                            padding: 0 6px;
                            &:before {
                                height: 1px;
                            }
                        }
                    }
                    &.confirm {
                        &.confirmed {
                            margin-top: auto;
                            margin-bottom: auto;
                            padding: 0 6px;
                            &:before {
                                height: 1px;
                            }
                        }
                    }
                }
            }

            .podcast {
                &.mod-buttons {
                    display: grid;
                    align-self: center;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .podcasts {
        padding: 10px;

        .podcast {
            &--title {
                font-size: 12px;
            }
            &--text {
                font-size: 12px;
                font-weight: 300;
            }
        }
    }
}
@media (max-width: 550px) {
    .podcasts {
        .podcast {
            &--info {
                img {
                    width: 70px;
                }
            }
        }
    }
}
