.menu {
  font-size: 20px;
  font-weight: bold;
  line-height: 2.1;
  letter-spacing: -0.43px;
  color: #fcfcfc;
  position: relative;
  width: 220px;

  &__selection {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__content {
    position: relative;
    padding: 15px;
    width: 100%;

    i {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #fcfcfc;
      margin-top: -8px;
      &:before {
        font-size: 12px;
        vertical-align: initial;
      }
    }
  }

  &__username {
    font-weight: bold;
    overflow: hidden;
    width: 165px;
    line-height: 37px;
    height: 40px;
    text-overflow: ellipsis;
  }

  &__type {
    opacity: 0.5;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: -0.09px;
    color: #ffffff;
    margin-top: 1px;
  }

  &__list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #eee;
    padding: 0 22px;
    box-sizing: border-box;
    display: none;
    z-index: 5;

    .menu--opened & {
      display: block;
    }
  }

  &__item {
    display: flex;
    padding: 14px 0;
    border-top: 1px solid #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.34px;
    color: #242831;
    align-items: center;
    cursor: pointer;

    &:first-child {
      border-top: 0;
    }

    i {
      margin-right: 20px;

      &:before {
        display: block;
        transform: rotate(-90deg);
      }
    }
  }

  &.menu--opened {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.35);
    background-color: #eee;
    color: #242831;

    i {
      color: #242831;
    }

    .menu__selection {
      &:before {
        content: "";
        position: absolute;
        background-color: #272b2f;
        width: 100%;
        left: -1px;
        height: 50%;
        z-index: -1;
        bottom: -1px;
        border: 1px solid #272b2f;
      }
    }
  }
}

a {
  text-decoration: none;
}

@media (max-width: 800px) {
  .app-logo {
    width: 100px;
    img {
      width: 100px;
    }
  }
  .menu {
    width: 200px;
    &__item {
      font-size: 14px;
    }
  }
}

@media (max-width: 650px) {
  .menu {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 500px) {
  .app-logo {
    width: 95px;
    img {
      width: 95px;
    }
  }
  .menu {
    font-size: 15px;
    width: 170px;
    &__content {
      i {
        top: 25px;
        &:before {
          font-size: 8px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .menu {
    position: relative;
    &__content {
      i {
        top: 15px;
      }
    }
  }
  .app-logo {
    width: 120px;
    height: 60px;
    img {
      width: 120px;
    }
  }
}
@media (max-width: 400px) {
  .app-logo {
    width: 90px;
    img {
      width: 90px;
    }
  }
  .menu {
    width: 170px;
    &__content {
      i {
        &:before {
          font-size: 8px;
        }
      }
    }
  }
  .menu__item {
    font-size: 12px;
  }
}
@media (max-width: 300px) {
  .app-logo {
    width: 80px;
    height: 50px;
    img {
      width: 80px;
    }
    margin-top: 20px;
  }
  .menu {
    width: 150px;
    &__username {
      font-size: 14px;
      width: 100px;
    }
    &__content {
      i {
        top: 15px;
        &:before {
          font-size: 8px;
        }
      }
    }
    &__list {
      padding: 0 15px;
    }
  }
}
@media (max-width: 280px) {
  .app-logo {
    // width: 70px;
    img {
      // width: 70px;
    }
  }
}
@media (max-width: 250px) {
  .app-logo {
    // width: 60px;
    img {
      // width: 60px;
    }
  }
}
@media (max-width: 225px) {
  .app-logo {
    // width: 50px;
    img {
      // width: 50px;
    }
  }
}
@media (max-width: 200px) {
  .app-logo {
    // width: 40px;
    img {
      // width: 40px;
    }
  }
}
@media (max-width: 180px) {
  .app-logo {
    // width: 40px;
    img {
      // width: 40px;
    }
  }
}
