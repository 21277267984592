.contract-box {
    color: #242831;
    margin-bottom: 15px;

    &--title {
        font-size: 16px;
        font-weight: 900;
        line-height: 2.63;
        margin: 0;
    }

    &--content {
        border-radius: 7px;
        border: solid 1px #d8d8d8;
        background-color: #fdfdfd;
        padding: 20px 20px 15px;
        box-sizing: border-box;
        height: 100%;
        &-info {
            overflow-y: scroll;
            max-height: 400px;
            padding: 0 10px;
            overflow-x: hidden;

        }

        h2 {
            font-size: 18px;
            font-weight: bold;
            line-height: 1.72;
            margin: 0;
        }

        p {
            font-size: 12px;
            line-height: 1.5;
        }
        ol , ul{
            padding: 0 20px;
            margin: 0;
        }
    }
}

::-webkit-scrollbar {
    width: 4px;
    border-radius: 4px;
    position:relative;
  }
  
  ::-webkit-scrollbar-track {
    background: #eeeeee; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: #bbf1fd; 
  }