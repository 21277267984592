#add-info-podcast-page {
    .upload-podcast-form {
        &.loading {
            opacity: 0.5;
        }
        display: flex;
        box-sizing: border-box;
        .input {
            max-width: 300px;
        }
        div {
            width: 100%;
        }

        &--img-area {
            max-width: 250px;
            margin-right: 30px;

            img {
                width: 100%;
            }
        }

        .button {
            width: 300px;
        }
    }
    .notification.error {
        color: #dc3636;
    }
    .modal__content--buttons {
        justify-content: center;
        button {
            margin: 5px;
            padding: 15px 30px !important;
        }
    }
    p.modal-notification {
        font-size: 16px;
        text-align: center;
    }
    p.align-text {
        // width: 330px;
        margin-left: -15px;
    }
}
