.input {
  margin-bottom: 10px;
  &.input--disabled {
    background: #bcbcbc14;
  }
  label {
    border-radius: 8px;
    border: solid 1px rgba(188, 188, 188, 0.7);
    display: flex;
  }
  &.rss {
    // width: 600px;
  }
  &.search {
    max-width: 589px;
  }

  &__area {
    flex-grow: 1;
    padding-top: 20px;
    padding-bottom: 8px;
    padding-left: 16px;
    position: relative;
    text-align: left;
  }

  &__label {
    font-size: 14px;
    color: #6c707c;
    font-weight: bold;
    position: absolute;
    top: 15px;
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out;

    .input--filled & {
      transform-origin: left top;
      transform: translate3d(0, -65%, 0);
      font-size: 12px;
    }
  }

  input {
    width: calc(100% - 20px);
    display: block;
    box-sizing: border-box;
    border: none;
    background: none;
    padding: 3px 16px 3px 0;
    font-size: 14px;
    font-weight: 500;
    color: #242831;
    font-family: "BrandonText", sans-serif;

    &:focus {
      outline: none;
    }
    &:before {
      content: icon;
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
    text-align: left;
  }

  &--error {
    label {
      border-color: #f3495a;
    }
    input,
    i,
    span.message {
      color: #f3495a;
    }
  }
  &--success {
    label {
      border-color: #126f12 !important;
    }
    input,
    i,
    span.message {
      color: #126f12 !important;
    }
  }
  &--already-taken {
    label {
      border-color: #1a83d0 !important;
    }
    input,
    i,
    span.message {
      color: #1a83d0 !important;
    }
  }
}

.modal__content {
  .input {
    width: 300px;
  }
}

.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
}

.icon {
  padding-top: 5px;
  color: #808080;
  min-width: 10px;
  &.delete {
    right: 25px;
  }
}

.input-field {
  margin-left: 25px;
}
a.icon-href {
  cursor: pointer;
}
a.none-link {
  cursor: unset;
}
@media (max-width: 600px){
  .modal__content {
    .input {
      width: 100%;
    }
  }
}